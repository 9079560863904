import React, { useContext, useEffect, useMemo, useState } from "react"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import _values from "lodash/values"
import _map from "lodash/map"

import SEOHeader from "../components/seo-header"
import Layout from "../components/layout"
import AuthContext from "../contexts/AuthContext"
import { database } from "../../firebaseConfig"
import { BOOKING_TYPES, BOOKING_TYPE_VALUES } from "../constants/common"
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database"
import { COLORS } from "../constants/colors"
import { getBookingsByType } from "../utils/common"
import BookingCard from "../components/BookingCard"
import BookingCardSmall from "../components/BookingsCardSmall"

const Bookings = () => {
  const [bookings, setBookings] = useState(null)
  const [bookingType, setBookingType] = useState(BOOKING_TYPE_VALUES.ACTIVE)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    let unsubscribe = () => {}
    if (!_isEmpty(user)) {
      const uid = _get(user, "uid")
      const bookingsRef = query(
        ref(database, "bookings"),
        ...[orderByChild("custId"), equalTo(`${uid}`)]
      )
      unsubscribe = onValue(bookingsRef, snapshot => {
        const bookingsResponse = snapshot.val()
        setBookings(_values(bookingsResponse))
      })
    }
    return unsubscribe
  }, [user])

  const renderBookingTypes = useMemo(
    () => (
      <div
        style={{
          display: "flex",
          columnGap: 16,
          paddingBottom: 20,
          backgroundColor: COLORS.PRIMARY_BACKGROUND,
        }}
      >
        {_map(BOOKING_TYPES, type => {
          let buttonStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
            padding: "0 16px",
          }
          let textStyle = { color: COLORS.WHITE }
          if (_get(type, "value") !== bookingType) {
            buttonStyle = {
              ...buttonStyle,
              backgroundColor: COLORS.BUTTON_COLORS.TERTIARY_COLOR,
              color: COLORS.TEXT_COLOR_SECONDARY,
            }
            textStyle = { ...textStyle, color: COLORS.TEXT_COLOR_SECONDARY }
          } else {
            buttonStyle = {
              ...buttonStyle,
              backgroundColor: COLORS.PRIMARY_COLOR,
            }
            textStyle = { ...textStyle, color: COLORS.WHITE }
          }
          return (
            <button
              onClick={() => setBookingType(_get(type, "value"))}
              className="py-2"
              style={{ ...buttonStyle, border: "none" }}
              key={_get(type, "value")}
            >
              <p className="mb-0" style={textStyle}>
                {_get(type, "label")}
              </p>
            </button>
          )
        })}
      </div>
    ),
    [bookingType]
  )

  const bookingsByType = useMemo(
    () => getBookingsByType(bookings, bookingType),
    [bookings, bookingType]
  )

  console.log({ bookingsByType })

  const renderBookingsByTypes = () => {
    return _map(bookingsByType, booking => {
      if (bookingType === BOOKING_TYPE_VALUES.ACTIVE) {
        return <BookingCard user={user} bookingInfo={booking} />
      }
      return <BookingCardSmall bookingInfo={booking} />
    })
  }

  const renderBookings = () => {
    return (
      <div>
        <h4>My Bookings</h4>
        {renderBookingTypes}

        <div className="container">
          <div className="row">
            {_isEmpty(bookingsByType) ? (
              <p className="bold" style={{ width: "100%" }}>
                No Bookings
              </p>
            ) : (
              renderBookingsByTypes()
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderNonLoggedInContent = () => {
    return (
      <p style={{ textAlign: "center" }}>Please login to view the bookings.</p>
    )
  }

  const renderContent = () => {
    if (_isEmpty(user)) return renderNonLoggedInContent()
    return renderBookings()
  }

  return (
    <div>
      <SEOHeader
        title={"My Bookings | GoFloaters"}
        description={"My Bookings | GoFloaters"}
      ></SEOHeader>

      <Layout type="meetingSpace">
        <div className="container newspaceDetailContainer">
          {renderContent()}
        </div>
      </Layout>
    </div>
  )
}

export default Bookings
